import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const PaymentPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [value] = useState(params.get('value') || '');
  const [name] = useState(params.get('name') || '');
  const [email] = useState(params.get('email') || '');
  const [cpf] = useState(params.get('cpf') || '');
  const [qrCode, setQrCode] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pixKey, setPixKey] = useState(null);

  useEffect(() => {
    const createPayment = async () => {
      if (!value || isNaN(value) || value <= 0) {
        setError('Valor inválido fornecido para criar o pagamento.');
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        console.log('Dados enviados para o backend:', { value, name, email, cpf });
        
        const response = await axios.post('https://backend-delivery-mt.vercel.app/api/create-payment', { 
          items: [
            {
              code: 'pagamentos_3d',
              quantity: 1,
              price: Number(value) * 100 // Valor em centavos
            }
          ],
          customer: {
            name: name || 'Cliente Anônimo',
            email: email || 'email@desconhecido.com',
            cpf: cpf || '00000000000',
          },
          payment: {
            method: 'pix'
          }
        });

        console.log('Resposta do backend:', response.data);

        if (response.data && response.data.paymentUrl) {
          setQrCode(response.data.paymentUrl);
          setPixKey(response.data.pixKey);
        } else {
          setError('URL de pagamento não recebida.');
        }
      } catch (error) {
        console.error('Erro ao criar pagamento:', error.response ? error.response.data : error.message);
        setError(`Erro ao criar pagamento: ${error.response?.data?.message || error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    createPayment();
  }, [value, name, email, cpf]);

  const handleStripePayment = async () => {
    try {
      const response = await axios.post('https://backend-delivery-mt.vercel.app/api/stripe-checkout', {
        items: [
          {
            code: 'pagamentos_3d',
            quantity: 1,
            price: Number(value) * 100, // Valor em centavos
          },
        ],
        customer: {
          name: name || 'Cliente Anônimo',
          email: email || 'email@desconhecido.com',
          cpf: cpf || '00000000000',
        },
      });

      window.location.href = response.data.url; // Redireciona para a URL de checkout do Stripe
    } catch (error) {
      console.error('Erro ao iniciar o pagamento com Stripe:', error);
      setError('Erro ao iniciar o pagamento. Tente novamente.');
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Página de Pagamento</h1>
      {isLoading ? (
        <p>Processando pagamento...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : qrCode ? (
        <div>
          <img src={qrCode} alt="QR Code de Pagamento" style={{ width: '300px', height: '300px' }} />
          <p style={{ marginTop: '20px', fontSize: '16px' }}>
            Chave Pix: <strong>{pixKey}</strong>
          </p>
          <button 
            onClick={handleStripePayment} 
            style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}
          >
            Pagar com Cartão
          </button>
        </div>
      ) : (
        <p>Redirecionando para a página de pagamento...</p>
      )}
    </div>
  );
};

export default PaymentPage;
